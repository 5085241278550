import React from 'react';
import SEO from 'react-seo-component';
import Layout from '~layouts/mainLayout';

import { useSiteMetadata } from '~hooks/useSiteMetadata';

import IntroCase from '~components/introCase';

import { FaArrowRight } from 'react-icons/fa';
import clientWor4 from "../assets/images/clientWork4.png";
import Fade from "react-reveal/Fade";
export default function Home() {
  const {
    title,
    titleTemplate,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();
  return (
    <>
      <SEO
        title={title}
        titleTemplate={titleTemplate}
        titleSeparator="|"
        description={description || 'Welcome to my’'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <Layout>
        <IntroCase title="Metavaders" subtitle="Building a Dapp which consisted of creating smart contracts and interacting with the Ethereum Blockchain with Web3." />
        <section className="mb-24">
     
       
     

        <div class="flex flex-wrap text-center justify-center ">
        <Fade bottom delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-amber mb-3">
      Challenge
    </h2>
    <p className="text-secondary prose-lg mb-6">
    The Metavaders is a generative collection of 10,101 space-themed NFTs. The company wanted to create a Decentralized application for their users to interact with the NFT they owned. This included coming up with a layout that matched their landing page. It
    
    also included creating smart contracts with Web3.       </p>
    </Fade>
      </div>
   
      <div class="container p-4 mb-10 lg:20 lg:px-20 mx-auto text-center ">
		<div class="relative w-full my-4 mr-auto rounded-2xl ">
			<img alt="Card" src={clientWor4} class="max-w-full rounded-lg "/>
        </div>
	
                <div class="col-span-12 lg:col-span-8 text-center">
                <div class="inline-block rounded-full text-white 
                                bg-red-400 hover:bg-red-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                               React
                            </div>
                            <div class="inline-block rounded-full text-white 
                                bg-blue-400 hover:bg-blue-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              Web3
                            </div>
                         
                            <div class="inline-block rounded-full text-white 
                                bg-yellow-400 hover:bg-yellow-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              Javascript
                            </div>
                            <div class="inline-block rounded-full text-white 
                                bg-green-400 hover:bg-green-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                                Blockchain
                            </div>
                          
                    </div>
  </div>
 

      <div class="flex flex-wrap text-center justify-center mb-20">
      <Fade bottom delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-green-500 mb-3">
      Solution
    </h2>
    <p className="text-secondary prose-lg mb-6">
    I had some prior experience with Crypto/Blockchain on my previous job as a software engineer at <a href="https://instaswap.io" target="_blank"><strong className="text-2xl">Instaswap</strong></a>. However being an NFT developer was rather different. Nevertheless, 
after asking the right questions, I managed to understand the needs of the client who gave me full creative freedom and autonomy on the project. Thankfully the docs of <a href="https://web3js.readthedocs.io/en/v1.5.2/web3-eth-contract.html" target="_blank"><strong className="text-2xl">Web3</strong></a> are well written, which facilitated the creation of those smart contracts with the use of metamask. 

<br />
<br />

The simple yet sharp <a href="https://app.metavaders.io" target="_blank"><strong className="text-2xl">Metavaders Dapp</strong></a> is build to provide the users owning the company's NFTs with one place to interact with them.
</p>
    </Fade>
      </div>
      <a href="https://opensea.io/collection/metavaders" target="_blank">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Visit</span> <span><FaArrowRight /></span> 
              </button>
        </a>
    
    </section>
      </Layout>
    </>
  );
}
